import { createSlice } from '@reduxjs/toolkit';

export const AppStateSlice = createSlice({
    name: 'AppState',
    initialState: {
        speed: 0.1,
        waittime: 120000,
        frame: 0,
        index: 0,
        loop: 0,
        opacity: 1,
        opacityinterval: null,
        isviewingframe: false,
        turntotalsecs: 0,
        waittotalsecs: 0,
        ispaused: false,        
        totalnfts: 0,
        isloaded: false,
        framepositions: [],
        debugtext: "",
        starttime: 0,
        isnextimage: false
    },
    reducers: {
        setspeed: (state, speed) => {
            state.speed = speed;
        },
        setwaittime: (state, waittime) => {
            state.waittime = waittime;
        },
        setframe: (state, frame) => {
            state.frame = frame;
        },
        setopacity: (state, opacity) => {
            state.opacity = opacity;
        },
        setopacityinterval: (state, opacityinterval) => {
            state.opacityinterval = opacityinterval;
        },
        setindex: (state, index) => {
            state.index = index;
        },
        setloop: (state, loop) => {
            state.loop = loop;
        },
        setisviewingframe: (state, isviewingframe) => {
            state.isviewingframe = isviewingframe;
        },
        setturntotalsecs: (state, turntotalsecs) => {
            state.turntotalsecs = turntotalsecs;
        },
        setwaittotalsecs: (state, waittotalsecs) => {
            state.waittotalsecs = waittotalsecs;
        },
        setispaused: (state, ispaused) => {
            state.ispaused = ispaused;
        },      
        settotalnfts: (state, totalnfts) => {
            state.totalnfts = totalnfts;
        },
        setisloaded: (state, isloaded) => {
            state.isloaded = isloaded;
        },
        setframepositions: (state, framepositions) => {            
            state.framepositions = framepositions.payload;
        },
        setdebugtext: (state, debugtext) => {
            state.debugtext = debugtext;
        },
        setstarttime: (state, starttime) => {
            state.starttime = starttime;
        },
        setnextimage: (state, isnextimage) => {
            state.isnextimage = isnextimage;
        },
    }
})

export const { setspeed,setwaittime,setframe,setindex,setloop,setispaused,setisviewingframe,setopacity,setwaittotalsecs,setturntotalsecs,setopacityinterval,setisloaded,setframepositions,setdebugtext,setstarttime,settotalnfts,setnextimage } = AppStateSlice.actions

export default AppStateSlice.reducer