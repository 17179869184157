import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  collectionSpinner: false,
};

export const spinSlice = createSlice({
  name: "spin",
  initialState,
  reducers: {
    setCollectionSpinner: (state, action) => {
      state.collectionSpinner = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCollectionSpinner } = spinSlice.actions;
export const getColelctionSpinner = ({ spin }) => spin?.collectionSpinner;
export default spinSlice.reducer;
