const getDate = (value) => {
    const date = new Date(value);
    const formattedDate = date.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
    return  (formattedDate && formattedDate !== 'Invalid Date') ? formattedDate : ''
}
export default getDate

export const getShortDate = (value) => {
    
  if ((value?.toString()??"") === "") return "";
  let formattedDate=  new Date(value).toLocaleDateString("en-us", {
        year: "numeric",
        month: "long",
        day: "numeric",
      })
    return  (formattedDate && formattedDate !== 'Invalid Date') ? formattedDate : ''
}


export const getDayMonth=(date)=>{
  let format=date?.split(",")[0];
  let extension;
  let day = format?.split(" ")[1]
  let lastDate=day?.slice(-1)
  if(day?.length!==1&&Number(day?.slice(0,1))!==1){
switch(Number(lastDate)){
  case 1:
  extension="st"
  break;
  case 2:
    extension="nd"
    break;
    case 3:
      extension="rd"
      break;
      default:
        extension="th"
        
}
  }
  else extension="th"
return `${format}${extension}`
}

export const categoryList = (data) => {
  let textVal = "";
  let categoryTextArray=Object.entries(data)
  categoryTextArray.forEach(([key, val], index) => {
    textVal = `${textVal}${key} • ${val} `;
  });
  return textVal;
};