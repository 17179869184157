import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    creatorProfile: {},
}

export const creatorSlice = createSlice({
  name: 'creator',
  initialState,
  reducers: {
    setCreatorProfile: (state, action) => {
        state.creatorProfile = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setCreatorProfile } = creatorSlice.actions
export const getCreatorProfile = ({creator}) => creator?.creatorProfile;
export default creatorSlice.reducer