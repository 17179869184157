import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    userFrameList: [],
    availableFrameData: []
}

export const frameSlice = createSlice({
  name: 'frame',
  initialState,
  reducers: {
    setUserFrameList: (state, action) => {
        state.userFrameList = [...state.userFrameList, ...action.payload]
    },
    setAvailableFrameData: (state, action) => {
        state.availableFrameData = [...state.availableFrameData, ...action.payload]
    },
  },
})

// Action creators are generated for each case reducer function
export const { setAvailableFrameData, setUserFrameList } = frameSlice.actions
export const getAvailableFrameData = ({frame}) => frame?.availableFrameData;
export const getUserFrameList = ({frame}) => frame?.userFrameList;
export default frameSlice.reducer