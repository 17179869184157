import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    currentView:""
}

export const userCollectionSlice = createSlice({
  name: 'userCollection',
  initialState,
  reducers: {
    setcurrentView: (state, action) => {
      state.currentView = action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const {setcurrentView} = userCollectionSlice.actions;
export const getcurrentView = ({userCollection}) => userCollection?.currentView;
export default userCollectionSlice.reducer;