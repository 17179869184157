import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    showAuthPop: false,
    loggingOutStatus:false
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setShowWalletPop : (state, action) => {
        state.showAuthPop = action.payload
    },
    setLoggingOutStatus : (state, action) => {
        state.loggingOutStatus = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setShowWalletPop,setLoggingOutStatus } = authSlice.actions
export const getShowWalletPop = ({auth}) => auth?.showAuthPop
export const getLoggingOutStatus = ({auth}) => auth?.loggingOutStatus
export default authSlice.reducer